/** @format */

import React, { Fragment } from 'react';
import {
  Divider,
  Grid,
  GridRow,
  GridColumn,
  Item,
  Header,
} from 'semantic-ui-react';

import { branch } from '../../lib/variables';

const Docs = () => (
  <Fragment>
    <Header as="h1" style={{ marginTop: '.1em' }} floated="left">
      Documentations
    </Header>
    <Divider clearing />

    <Grid divided="vertically">
      <GridRow columns={1}>
        <GridColumn>
          <Item.Group divided>
            {branch !== 'smat' && (
              <Item>
                <Item.Image src={'/assets/images/docv1.png'} size="big" />

                <Item.Content>
                  <Item.Header>
                    <a href="https://doc.smartcitylab.io/v1" target="blank">
                      API v1
                    </a>
                  </Item.Header>
                  <Item.Description>
                    <span className="url">
                      <a href="https://doc.smartcitylab.io/v1" target="blank">
                        https://doc.smartcitylab.io/v1
                      </a>
                    </span>
                  </Item.Description>
                </Item.Content>
              </Item>
            )}

            {branch === 'smat' && (
              <>
                <Item>
                  <Item.Image src={'/assets/images/docv1.png'} size="big" />

                  <Item.Content>
                    <Item.Header>
                      <a href="https://doc.smartcitylab.io/smat" target="blank">
                        MQTT v2
                      </a>
                    </Item.Header>
                    <Item.Description>
                      <span className="url">
                        <a
                          href="https://doc.smartcitylab.io/smat"
                          target="blank"
                        >
                          https://doc.smartcitylab.io/smat
                        </a>
                      </span>
                    </Item.Description>
                  </Item.Content>
                </Item>
              </>
            )}
          </Item.Group>
        </GridColumn>
      </GridRow>

      <GridRow columns={2}>
        <GridColumn>
          <Item.Group divided>
            <Item>
              <Item.Image src={'/assets/images/docv2.png'} size="big" />

              <Item.Content>
                <Item.Header>
                  <a href="https://doc.v2.smartcitylab.io" target="blank">
                    API v2
                  </a>
                </Item.Header>
                <Item.Description>
                  <span className="url">
                    <a href="https://doc.v2.smartcitylab.io" target="blank">
                      https://doc.v2.smartcitylab.io
                    </a>
                  </span>
                </Item.Description>
              </Item.Content>
            </Item>
          </Item.Group>
        </GridColumn>
        <GridColumn>
          <Item.Group divided>
            <Item>
              <Item.Image src={'/assets/images/docv2mqtt.png'} size="big" />

              <Item.Content>
                <Item.Header>
                  <a href="https://doc.smartcitylab.io/v2-mqtt" target="blank">
                    MQTT v2
                  </a>
                </Item.Header>
                <Item.Description>
                  <span className="url">
                    <a
                      href="https://doc.smartcitylab.io/v2-mqtt"
                      target="blank"
                    >
                      https://doc.smartcitylab.io/v2-mqtt
                    </a>
                  </span>
                </Item.Description>
              </Item.Content>
            </Item>
          </Item.Group>
        </GridColumn>
      </GridRow>
    </Grid>
  </Fragment>
);

export { Docs };
